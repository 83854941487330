import React from "react";

const CandidateHE = ({
  expanded,
  toggleSection,
  higherEducations,
  handleEducationChange,
  handleAddEducation,
  handleRemoveEducation,
  subjectOptions,
}) => {
  return (
    <>
      <h2
        className="text-blue-800 col-span-2 text-lg font-semibold dark:text-white cursor-pointer"
        onClick={() => toggleSection("higherEducation")}
      >
        Higher Education
        <hr className="my-4 border-gray-300 col-span-2" />
      </h2>
      {expanded && (
        <>
          {higherEducations.map((education, index) => (
            <div key={index} className="border-b border-gray-300 pb-4 mb-4">
              <h3 className="text-md font-semibold text-gray-700 dark:text-white">
                Education {index + 1}
              </h3>

              {[
                {
                  label: "Start Date",
                  type: "date",
                  name: "startDate",
                  //required: true,
                },
                {
                  label: "End Date",
                  type: "date",
                  name: "endDate",
                  //required: false,
                },
                {
                  label: "Institution",
                  type: "text",
                  name: "institution",
                  //required: true,
                },
                {
                  label: "Degree",
                  type: "select",
                  name: "degree",
                  options: [
                    { value: "", label: "(not specified)" },
                    { value: "Bachelor", label: "Bachelor" },
                    { value: "Diploma", label: "Diploma" },
                    { value: "Doctorate", label: "Doctorate" },
                    { value: "Habilitation", label: "Habilitation" },
                    { value: "Master", label: "Master" },
                    { value: "MBA", label: "MBA" },
                  ],
                  //required: true,
                },
                {
                  label: "Subject",
                  type: "select",
                  name: "subject",
                  options: subjectOptions,
                  //required: true,
                },
                { label: "Major", type: "text", name: "major", 
                  //required: true 
                },
                {
                  label: "Grade",
                  type: "text",
                  name: "grade",
                  //required: false,
                },
                {
                  label: "Completed",
                  type: "checkbox",
                  name: "completed",
                  //required: false,
                },
                {
                  label: "Notes",
                  type: "textarea",
                  name: "notes",
                  //required: false,
                  className: "col-span-2",
                },
              ].map((field, fieldIndex) => (
                <div
                  key={fieldIndex}
                  className={`p-2 ${field.className || "col-span-1"}`}
                >
                  <label
                    htmlFor={`${field.name}-${index}`}
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    {field.label}
                    {field.required && <span className="text-red-500">*</span>}
                  </label>
                  {field.type === "select" ? (
                    <select
                      name={field.name}
                      id={`${field.name}-${index}`}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      required={field.required}
                      value={education[field.name]}
                      onChange={(e) =>
                        handleEducationChange(index, field.name, e.target.value)
                      }
                    >
                      {field.options.map((option, optionIndex) => (
                        <option key={optionIndex} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  ) : field.type === "textarea" ? (
                    <textarea
                      name={field.name}
                      id={`${field.name}-${index}`}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      required={field.required}
                      value={education[field.name]}
                      onChange={(e) =>
                        handleEducationChange(index, field.name, e.target.value)
                      }
                    />
                  ) : field.type === "checkbox" ? (
                    <input
                      type="checkbox"
                      name={field.name}
                      id={`${field.name}-${index}`}
                      className="h-4 w-4 text-primary-600 border-gray-300 rounded focus:ring-primary-500 dark:bg-gray-700 dark:border-gray-600"
                      checked={education[field.name] || false}
                      onChange={(e) =>
                        handleEducationChange(
                          index,
                          field.name,
                          e.target.checked
                        )
                      }
                    />
                  ) : (
                    <input
                      type={field.type}
                      name={field.name}
                      id={`${field.name}-${index}`}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      required={field.required}
                      value={education[field.name]}
                      onChange={(e) =>
                        handleEducationChange(index, field.name, e.target.value)
                      }
                    />
                  )}
                </div>
              ))}
              <button
                type="button"
                onClick={() => handleRemoveEducation(index)}
                className="text-red-500 text-sm underline mt-2"
              >
                Remove Education
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={handleAddEducation}
            className="mt-4 bg-blue-500 text-white px-4 py-2 rounded max-h-10"
          >
            Add Another Education
          </button>
        </>
      )}
    </>
  );
};

export default CandidateHE;
