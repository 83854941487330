import React from "react";

const CandidateLA = ({
  expanded,
  toggleSection,
  languages,
  handleLanguageChange,
  handleAddLanguage,
  handleRemoveLanguage,
  languageOptions,
}) => {
  return (
    <>
      <h2
        className="text-blue-800 col-span-2 text-lg font-semibold dark:text-white cursor-pointer"
        onClick={() => toggleSection("languages")}
      >
        Languages
        <hr className="my-4 border-gray-300 col-span-2" />
      </h2>
      {expanded && (
        <>
          {languages.map((language, index) => (
            <div key={index} className="border-b border-gray-300 pb-4 mb-4">
              <h3 className="text-md font-semibold text-gray-700 dark:text-white">
                Language {index + 1}
              </h3>

              {/* Language */}
              <div className="p-2 col-span-1">
                <label
                  htmlFor={`language-${index}`}
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Language <span className="text-red-500">*</span>
                </label>
                <select
                  name="language"
                  id={`language-${index}`}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                  //required
                  value={language.language}
                  onChange={(e) =>
                    handleLanguageChange(index, "language", e.target.value)
                  }
                >
                  <option value="">(not specified)</option>
                  {languageOptions.map((option, optionIndex) => (
                    <option key={optionIndex} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>

              {/* Proficiency */}
              <div className="p-2 col-span-1">
                <label
                  htmlFor={`proficiency-${index}`}
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Proficiency <span className="text-red-500">*</span>
                </label>
                <select
                  name="proficiency"
                  id={`proficiency-${index}`}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                  required
                  value={language.proficiency}
                  onChange={(e) =>
                    handleLanguageChange(index, "proficiency", e.target.value)
                  }
                >
                  <option value="">(not specified)</option>
                  <option value="Basic">Basic</option>
                  <option value="Intermediate">Intermediate</option>
                  <option value="Advanced">Advanced</option>
                  <option value="Fluent">Fluent</option>
                </select>
              </div>

              {/* Remove Button */}
              <button
                type="button"
                onClick={() => handleRemoveLanguage(index)}
                className="text-red-500 text-sm underline mt-2"
              >
                Remove Language
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={handleAddLanguage}
            className="mt-4 bg-blue-500 text-white px-4 py-2 rounded max-h-10"
          >
            Add Another Language
          </button>
        </>
      )}
    </>
  );
};

export default CandidateLA;
