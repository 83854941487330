import React, { useEffect, useState } from "react";

const RegistrationForm = () => {
  const [formData, setFormData] = useState({
    business: "",
    subBusiness: "",
    jpNumber: "",
    position: "",
    gender: "(not specified)",
    religion: "(not specified)",
    homeStatus: "(not specified)",
    bloodType: "(not specified)",
    maritalStatus: "(not specified)",
    idType: "(not specified)",
  });

  const [citizenshipOptions, setCitizenshipOptions] = useState([]);
  const [nationalityOptions, setNationalityOptions] = useState([]);
  const [regionOptions, setRegionOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);

  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [filteredCountryOptions, setFilteredCountryOptions] = useState([]);
  const [filteredCityOptions, setFilteredCityOptions] = useState([]);
  const [filteredStateOptions, setFilteredStateOptions] = useState([]);

  const [selectedRegionSecondary, setSelectedRegionSecondary] = useState("");
  const [selectedCountrySecondary, setSelectedCountrySecondary] = useState("");
  const [filteredCountryOptionsSecondary, setFilteredCountryOptionsSecondary] =
    useState([]);
  const [filteredCityOptionsSecondary, setFilteredCityOptionsSecondary] =
    useState([]);
  const [filteredStateOptionsSecondary, setFilteredStateOptionsSecondary] =
    useState([]);

    
  const [subjectOptions, setSubjectOptions] = useState([]);

  // State untuk mengontrol expand/collapse
  const [expandedSections, setExpandedSections] = useState({
    personalInfo: true,
    homeAddress: false,
    secondaryAddress: false,
    contact: false,
    emergencyContact: false,
    citizenship: false,
  });

  useEffect(() => {
    const jpnumber = localStorage.getItem("jpnumber");
    console.log("jpnumber", jpnumber);
    fetch(
      `https://api.sinergiteknologi.co.id/mmgwebapi/get/joblists?JPNumber=${jpnumber}`
    )
      .then((response) => {
        // Check if the response is okay
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        // Check if data is empty
        if (data.length === 0) {
          console.error("No data found");
          return; // or handle the case where no data is found
        }
        setFormData({
          business: data[0].Business || "",
          subBusiness: data[0].SubBusiness || "",
          jpNumber: data[0].JPNumber || "",
          position: data[0].Position || "",
        });
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });

    const fetchOptions = async (url, setter, mapFunc) => {
      try {
        const response = await fetch(url);
        const data = await response.json();
        if (Array.isArray(data)) {
          setter(mapFunc(data));
        } else {
          console.error(`Unexpected response structure from ${url}:`, data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchOptions(
      "https://api.sinergiteknologi.co.id/mmgwebapi/get/Nationality",
      setNationalityOptions,
      (data) =>
        data.map((item) => ({
          value: item.NationalityCode,
          label: item.NationalityName,
        }))
    );

    fetchOptions(
      "https://api.sinergiteknologi.co.id/mmgwebapi/get/Citizenship",
      setCitizenshipOptions,
      (data) =>
        data.map((item) => ({
          value: item.CitizenCode,
          label: item.CitizenName,
        }))
    );

    fetchOptions(
      "https://api.sinergiteknologi.co.id/mmgwebapi/get/Region",
      setRegionOptions,
      (data) =>
        data.map((item) => ({ value: item.RegionCode, label: item.RegionName }))
    );

    fetchOptions(
      "https://api.sinergiteknologi.co.id/mmgwebapi/get/Country",
      setCountryOptions,
      (data) =>
        data.map((item) => ({
          value: item.CountryCode,
          label: item.CountryName,
          regionCode: item.RegionCode,
        }))
    );

    fetchOptions(
      "https://api.sinergiteknologi.co.id/mmgwebapi/get/City",
      setCityOptions,
      (data) =>
        data.map((item) => ({
          value: item.CityCode,
          label: item.CityName,
          countryCode: item.CountryCode,
        }))
    );

    fetchOptions(
      "https://api.sinergiteknologi.co.id/mmgwebapi/get/State",
      setStateOptions,
      (data) =>
        data.map((item) => ({
          value: item.StateCode,
          label: item.StateName,
          countryCode: item.CountryCode,
        }))
    );
    fetchOptions(
      "https://api.sinergiteknologi.co.id/mmgwebapi/get/State",
      setSubjectOptions,
      (data) =>
        data.map((item) => ({
          value: item.StateCode,
          label: item.StateName,
          countryCode: item.CountryCode,
        }))
    );
  }, []);

  const handleRegionChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedRegion(selectedValue);

    if (selectedValue) {
      const filteredCountries = countryOptions.filter(
        (country) => country.regionCode === selectedValue
      );
      setFilteredCountryOptions(filteredCountries);
      setSelectedCountry(""); // Reset country selection
      setFilteredCityOptions([]); // Reset city selection
      setFilteredStateOptions([]); // Reset state selection
    } else {
      setFilteredCountryOptions(countryOptions); // Show all countries if no region is selected
    }
  };

  const handleCountryChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedCountry(selectedValue);

    if (selectedValue) {
      const filteredCities = cityOptions.filter(
        (city) => city.countryCode === selectedValue
      );
      const filteredStates = stateOptions.filter(
        (state) => state.countryCode === selectedValue
      );
      setFilteredCityOptions(filteredCities);
      setFilteredStateOptions(filteredStates);
    } else {
      setFilteredCityOptions([]); // Reset city selection if no country is selected
      setFilteredStateOptions([]); // Reset state selection if no country is selected
    }
  };

  const handleRegionSecondaryChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedRegionSecondary(selectedValue);

    if (selectedValue) {
      const filteredCountries = countryOptions.filter(
        (country) => country.regionCode === selectedValue
      );
      setFilteredCountryOptionsSecondary(filteredCountries);
      setSelectedCountrySecondary(""); // Reset country selection
      setFilteredCityOptionsSecondary([]); // Reset city selection
      setFilteredStateOptionsSecondary([]); // Reset state selection
    } else {
      setFilteredCountryOptionsSecondary(countryOptions); // Show all countries if no region is selected
    }
  };

  const handleCountrySecondaryChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedCountrySecondary(selectedValue);

    if (selectedValue) {
      const filteredCities = cityOptions.filter(
        (city) => city.countryCode === selectedValue
      );
      const filteredStates = stateOptions.filter(
        (state) => state.countryCode === selectedValue
      );
      setFilteredCityOptionsSecondary(filteredCities);
      setFilteredStateOptionsSecondary(filteredStates);
    } else {
      setFilteredCityOptionsSecondary([]); // Reset city selection if no country is selected
      setFilteredStateOptionsSecondary([]); // Reset state selection if no country is selected
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };
  const toggleSection = (section) => {
    setExpandedSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <section className="bg-gray-50 dark:bg-gray-900">
      <div className="flex flex-col items-center justify-center px-6 pb-8 mx-auto md:h-screen lg:py-0">
        <div
          className="w-full bg-white rounded-lg shadow dark:border md:mt-0 xl:p-0 dark:bg-gray-800 dark:border-gray-700"
          style={{ maxWidth: "1200px" }}
        >
          <div
            className="p-6 space-y-4 md:space-y-6 sm:p-8"
            style={{ maxHeight: "500px", overflowY: "auto" }}
          >
            <div className="flex flex-col items-center justify-center">
              <a className="text-2xl font-semibold text-gray-900 dark:text-white">
                <img className="h-48" src="/img/logoMMG.png" alt="logo" />
              </a>
              <h1 className="text-2xl font-bold text-center leading-tight tracking-tight text-blue-900 md:text-2xl dark:text-white">
                Candidate Form
              </h1>
            </div>
            <form
              className="grid grid-cols-1 sm:grid-cols-2 gap-4"
              onSubmit={handleSubmit}
            >
              <h2
                className="text-blue-800 col-span-2 text-lg font-semibold dark:text-white cursor-pointer"
                onClick={() => toggleSection("personalInfo")}
              >
                Personal Information
                <hr className="my-4 border-gray-300 col-span-2" />
              </h2>
              {expandedSections.personalInfo && (
                <>
                  {[
                    {
                      label: "Candidate Name",
                      type: "text",
                      name: "candidateName",
                      required: true,
                      className: "col-span-2",
                    },
                    {
                      label: "Candidate ID",
                      type: "text",
                      name: "candidateID",
                      readOnly: true,
                    },
                    {
                      label: "Short Name",
                      type: "text",
                      name: "shortName",
                    },
                    {
                      label: "Business",
                      type: "text",
                      name: "business",
                      required: true,
                      readOnly: true,
                    },
                    {
                      label: "Sub Business",
                      type: "text",
                      name: "subBusiness",
                      required: true,
                      readOnly: true,
                    },
                    {
                      label: "Job No.",
                      type: "text",
                      name: "jpNumber",
                      required: true,
                      readOnly: true,
                    },
                    {
                      label: "Position",
                      type: "text",
                      name: "position",
                      required: true,
                      readOnly: true,
                    },
                    {
                      label: "Birth Place",
                      type: "text",
                      name: "birthPlace",
                      required: true,
                    },
                    {
                      label: "Birth Date",
                      type: "date",
                      name: "birthDate",
                    },
                    {
                      label: "Gender",
                      type: "select",
                      name: "gender",
                      options: ["(not specified)", "Male", "Female"],
                    },
                    {
                      label: "Blood Type",
                      type: "select",
                      name: "bloodType",
                      options: ["(not specified)", "O", "A", "B", "AB"],
                    },
                    {
                      label: "Religion",
                      type: "select",
                      name: "religion",
                      options: [
                        "(not specified)",
                        "Islam",
                        "Kristen",
                        "Katolik",
                        "Budha",
                        "Hindu",
                        "Konghucu",
                      ],
                    },
                    {
                      label: "Marital Status",
                      type: "select",
                      name: "maritalStatus",
                      options: [
                        "(not specified)",
                        "Civil union",
                        "Divorced",
                        "Married",
                        "Unmarried",
                        "Widowed",
                      ],
                    },
                    {
                      label: "Weight",
                      type: "number",
                      name: "weight",
                      required: true,
                      min: 0,
                    },
                    {
                      label: "Height",
                      type: "number",
                      name: "height",
                      required: true,
                      min: 0,
                    },
                    {
                      label: "Home Status",
                      type: "select",
                      name: "homeStatus",
                      options: [
                        "(not specified)",
                        "Milik Sendiri",
                        "Sewa",
                        "Keluarga",
                      ],
                    },
                    {
                      label: "ID Type",
                      type: "select",
                      name: "idType",
                      options: ["(not specified)", "KTP", "SIM", "Paspor"],
                    },
                    {
                      label: "ID No.",
                      type: "text",
                      name: "idNo",
                      required: true,
                    },
                    {
                      label: "ID No Exp Date",
                      type: "date",
                      name: "idNoExpDate",
                    },
                    {
                      label: "Driver License No",
                      type: "text",
                      name: "driverLicenseNo",
                    },
                    {
                      label: "Exp Date",
                      type: "date",
                      name: "driverLicenseExp",
                    },
                    {
                      label: "Achievement",
                      type: "textarea",
                      name: "achievement",
                      className: "col-span-2",
                    },
                    {
                      label: "Latest Salary",
                      type: "number",
                      name: "latestSalary",
                      min: 0,
                    },
                    {
                      label: "Expected Salary",
                      type: "number",
                      name: "expSalary",
                      required: true,
                      min: 0,
                    },
                  ].map((field, index) => (
                    <div
                      key={index}
                      className={`p-2 ${field.className || "col-span-1"}`}
                    >
                      <label
                        htmlFor={field.name}
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        {field.label}
                        {field.required && (
                          <span className="text-red-500">*</span>
                        )}{" "}
                        {/* Menambahkan ikon required */}
                      </label>
                      {field.type === "select" ? (
                        <select
                          id={field.name}
                          name={field.name}
                          value={formData[field.name]}
                          onChange={handleChange}
                          required={field.required}
                          className="block w-full p-2 border border-gray-300 rounded"
                        >
                          {field.options.map((option, idx) => (
                            <option key={idx} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      ) : field.type === "textarea" ? (
                        <textarea
                          id={field.name}
                          name={field.name}
                          value={formData[field.name]}
                          onChange={handleChange}
                          className="block w-full p-2 border border-gray-300 rounded"
                        />
                      ) : (
                        <input
                          id={field.name}
                          name={field.name}
                          type={field.type}
                          value={formData[field.name]}
                          onChange={handleChange}
                          required={field.required}
                          className="block w-full p-2 border border-gray-300 rounded"
                          min={field.min}
                          readOnly={field.readOnly}
                        />
                      )}
                    </div>
                  ))}
                </>
              )}
              <h2
                className="text-blue-800 col-span-2 text-lg font-semibold dark:text-white cursor-pointer"
                onClick={() => toggleSection("homeAddress")}
              >
                Home Address
                <hr className="my-4 border-gray-300 col-span-2" />
              </h2>
              {expandedSections.homeAddress && (
                <>
                  {[
                    {
                      label: "Region",
                      type: "select",
                      name: "region",
                      options: regionOptions,
                      required: true,
                    },
                    {
                      label: "Country",
                      type: "select",
                      name: "country",
                      options: filteredCountryOptions,
                      required: true,
                    },
                    {
                      label: "City",
                      type: "select",
                      name: "city",
                      options: filteredCityOptions,
                      required: true,
                    },
                    {
                      label: "State",
                      type: "select",
                      name: "state",
                      options: filteredStateOptions,
                      required: true,
                    },
                    { label: "ZIP", type: "text", name: "zip", required: true },
                    {
                      label: "Address",
                      type: "text",
                      name: "address",
                      required: true,
                    },
                  ].map((field, index) => (
                    <div
                      key={index}
                      className={`p-2 ${field.className || "col-span-1"}`}
                    >
                      <label
                        htmlFor={field.name}
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        {field.label}
                        {field.required && (
                          <span className="text-red-500">*</span>
                        )}
                      </label>
                      {field.type === "select" ? (
                        <select
                          name={field.name}
                          id={field.name}
                          className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                            field.readOnly
                              ? "bg-gray-200 cursor-not-allowed"
                              : ""
                          }`}
                          required={field.required}
                          onChange={
                            field.name === "region"
                              ? handleRegionChange
                              : field.name === "country"
                              ? handleCountryChange
                              : null
                          }
                          disabled={field.readOnly}
                        >
                          <option value="">Select {field.label}</option>
                          {field.options?.map((option, idx) => (
                            <option key={idx} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <input
                          type={field.type}
                          name={field.name}
                          id={field.name}
                          className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                            field.readOnly
                              ? "bg-gray-200 cursor-not-allowed"
                              : ""
                          }`}
                          required={field.required}
                          readOnly={field.readOnly}
                        />
                      )}
                    </div>
                  ))}
                </>
              )}
              <h2
                className="text-blue-800 col-span-2 text-lg font-semibold dark:text-white cursor-pointer"
                onClick={() => toggleSection("secondaryAddress")}
              >
                Secondary Address
                <hr className="my-4 border-gray-300 col-span-2" />
              </h2>
              {expandedSections.secondaryAddress && (
                <>
                  {[
                    {
                      label: "Region",
                      type: "select",
                      name: "region-secondary",
                      options: regionOptions, // Tetap menggunakan regionOptions yang sama
                      required: true,
                    },
                    {
                      label: "Country",
                      type: "select",
                      name: "country-secondary",
                      options: filteredCountryOptionsSecondary, // Ganti dengan filteredCountryOptionsSecondary
                      required: true,
                    },
                    {
                      label: "City ",
                      type: "select",
                      name: "city-secondary",
                      options: filteredCityOptionsSecondary, // Ganti dengan filteredCityOptionsSecondary
                      required: true,
                    },
                    {
                      label: "State",
                      type: "select",
                      name: "state-secondary",
                      options: filteredStateOptionsSecondary, // Ganti dengan filteredStateOptionsSecondary
                      required: true,
                    },
                    {
                      label: "ZIP",
                      type: "text",
                      name: "zip-secondary",
                      required: true,
                    },
                    {
                      label: "Address",
                      type: "text",
                      name: "address-secondary",
                      required: true,
                    },
                  ].map((field, index) => (
                    <div
                      key={index}
                      className={`p-2 ${field.className || "col-span-1"}`}
                    >
                      <label
                        htmlFor={field.name}
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        {field.label}
                        {field.required && (
                          <span className="text-red-500">*</span>
                        )}
                      </label>
                      {field.type === "select" ? (
                        <select
                          name={field.name}
                          id={field.name}
                          className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                            field.readOnly
                              ? "bg-gray-200 cursor-not-allowed"
                              : ""
                          }`}
                          required={field.required}
                          onChange={
                            field.name === "region-secondary"
                              ? handleRegionSecondaryChange
                              : field.name === "country-secondary"
                              ? handleCountrySecondaryChange
                              : null
                          }
                          disabled={field.readOnly}
                        >
                          <option value="">Select {field.label}</option>
                          {field.options?.map((option, idx) => (
                            <option key={idx} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <input
                          type={field.type}
                          name={field.name}
                          id={field.name}
                          className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                            field.readOnly
                              ? "bg-gray-200 cursor-not-allowed"
                              : ""
                          }`}
                          required={field.required}
                          readOnly={field.readOnly}
                        />
                      )}
                    </div>
                  ))}
                </>
              )}
              <h2
                className="text-blue-800 col-span-2 text-lg font-semibold dark:text-white cursor-pointer"
                onClick={() => toggleSection("contact")}
              >
                Contact
                <hr className="my-4 border-gray-300 col-span-2" />
              </h2>
              {expandedSections.contact && (
                <>
                  {[
                    {
                      label: "Email",
                      type: "email",
                      name: "email",
                      required: true,
                    },
                    {
                      label: "Phone",
                      type: "tel",
                      name: "phone",
                      required: true,
                    },
                    { label: "Fax", type: "tel", name: "fax", required: false },
                    {
                      label: "Mobile",
                      type: "tel",
                      name: "mobile",
                      required: true,
                    },
                  ].map((field, index) => (
                    <div
                      key={index}
                      className={`p-2 ${field.className || "col-span-1"}`}
                    >
                      <label
                        htmlFor={field.name}
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        {field.label}
                        {field.required && (
                          <span className="text-red-500">*</span>
                        )}{" "}
                        {/* Menambahkan ikon required */}
                      </label>
                      {field.type === "select" ? (
                        <select
                          id={field.name}
                          name={field.name}
                          value={formData[field.name]}
                          onChange={handleChange}
                          required={field.required}
                          className="block w-full p-2 border border-gray-300 rounded"
                        >
                          {field.options.map((option, idx) => (
                            <option key={idx} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      ) : field.type === "textarea" ? (
                        <textarea
                          id={field.name}
                          name={field.name}
                          value={formData[field.name]}
                          onChange={handleChange}
                          className="block w-full p-2 border border-gray-300 rounded"
                        />
                      ) : (
                        <input
                          id={field.name}
                          name={field.name}
                          type={field.type}
                          value={formData[field.name]}
                          onChange={handleChange}
                          required={field.required}
                          className="block w-full p-2 border border-gray-300 rounded"
                          min={field.min}
                          readOnly={field.readOnly}
                        />
                      )}
                    </div>
                  ))}
                </>
              )}
              <h2
                className="text-blue-800 col-span-2 text-lg font-semibold dark:text-white cursor-pointer"
                onClick={() => toggleSection("emergencyContact")}
              >
                Emergency Contact
                <hr className="my-4 border-gray-300 col-span-2" />
              </h2>
              {expandedSections.emergencyContact && (
                <>
                  {[
                    {
                      label: "Name",
                      type: "text",
                      name: "emergencyName",
                      required: true,
                    },
                    {
                      label: "Relationship",
                      type: "select",
                      name: "relationship",
                      options: [
                        "(not specified)",
                        "Former Supervisor",
                        "Former Manager",
                        "Former Director",
                        "Partner",
                        "Other",
                      ],
                    },
                    {
                      label: "Phone",
                      type: "tel",
                      name: "emergencyPhone",
                      required: true,
                    },
                    {
                      label: "Address",
                      type: "text",
                      name: "emergencyAddress",
                      required: true,
                    },
                  ].map((field, index) => (
                    <div
                      key={index}
                      className={`p-2 ${field.className || "col-span-1"}`}
                    >
                      <label
                        htmlFor={field.name}
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        {field.label}
                        {field.required && (
                          <span className="text-red-500">*</span>
                        )}{" "}
                        {/* Menambahkan ikon required */}
                      </label>
                      {field.type === "select" ? (
                        <select
                          id={field.name}
                          name={field.name}
                          value={formData[field.name]}
                          onChange={handleChange}
                          required={field.required}
                          className="block w-full p-2 border border-gray-300 rounded"
                        >
                          {field.options.map((option, idx) => (
                            <option key={idx} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      ) : field.type === "textarea" ? (
                        <textarea
                          id={field.name}
                          name={field.name}
                          value={formData[field.name]}
                          onChange={handleChange}
                          className="block w-full p-2 border border-gray-300 rounded"
                        />
                      ) : (
                        <input
                          id={field.name}
                          name={field.name}
                          type={field.type}
                          value={formData[field.name]}
                          onChange={handleChange}
                          required={field.required}
                          className="block w-full p-2 border border-gray-300 rounded"
                          min={field.min}
                          readOnly={field.readOnly}
                        />
                      )}
                    </div>
                  ))}
                </>
              )}
              <h2
                className="text-blue-800 col-span-2 text-lg font-semibold dark:text-white cursor-pointer"
                onClick={() => toggleSection("citizenship")}
              >
                Citizenship
                <hr className="my-4 border-gray-300 col-span-2" />
              </h2>
              {expandedSections.citizenship && (
                <>
                  {[
                    {
                      label: "Citizenship",
                      type: "select",
                      name: "citizenship",
                      options: citizenshipOptions,
                      required: true,
                    },
                    {
                      label: "Nationality",
                      type: "select",
                      name: "nationality",
                      options: nationalityOptions,
                      required: true,
                    },
                  ].map((field, index) => (
                    <div key={index} className="p-2 col-span-1">
                      <label
                        htmlFor={field.name}
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        {field.label}
                      </label>
                      <select
                        name={field.name}
                        id={field.name}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        required={field.required}
                      >
                        <option value="">Select {field.label}</option>
                        {field.options?.map((option, idx) => (
                          <option key={idx} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  ))}
                </>
              )}
              <h2
                className="text-blue-800 col-span-2 text-lg font-semibold dark:text-white cursor-pointer"
                onClick={() => toggleSection("higherEducation")}
              >
                Higher Education
                <hr className="my-4 border-gray-300 col-span-2" />
              </h2>
              {expandedSections.higherEducation && (
                <>
                  {[
                    {
                      label: "Start Date",
                      type: "date",
                      name: "startDate",
                      required: true,
                    },
                    {
                      label: "End Date",
                      type: "date",
                      name: "endDate",
                      required: false,
                    },
                    {
                      label: "Institution",
                      type: "text",
                      name: "institution",
                      required: true,
                    },
                    {
                      label: "Degree",
                      type: "select",
                      name: "degree",
                      options: [
                        { value: "", label: "Select Degree" },
                        { value: "not specified", label: "(not specified)" },
                        { value: "Bachelor", label: "Bachelor" },
                        { value: "Diploma", label: "Diploma" },
                        { value: "Doctorate", label: "Doctorate" },
                        { value: "Habilitation", label: "Habilitation" },
                        { value: "Master", label: "Master" },
                        { value: "MBA", label: "MBA" },
                      ],
                      required: true,
                    },
                    {
                      label: "Subject",
                      type: "select",
                      name: "subject",
                      options: subjectOptions, // Pastikan subjects diambil dari API
                      required: true,
                    },
                    {
                      label: "Major",
                      type: "text",
                      name: "major",
                      required: true,
                    },
                    {
                      label: "Completed",
                      type: "checkbox",
                      name: "completed",
                      required: false,
                    },
                    {
                      label: "Grade",
                      type: "text",
                      name: "grade",
                      required: false,
                    },
                    {
                      label: "Notes",
                      type: "textarea",
                      name: "notes",
                      required: false,
                    },
                  ].map((field, index) => (
                    <div
                      key={index}
                      className={`p-2 ${field.className || "col-span-1"}`}
                    >
                      <label
                        htmlFor={field.name}
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        {field.label}
                        {field.required && (
                          <span className="text-red-500">*</span>
                        )}
                      </label>
                      {field.type === "select" ? (
                        <select
                          name={field.name}
                          id={field.name}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                          required={field.required}
                          onChange={handleChange}
                        >
                          {field.options?.map((option, idx) => (
                            <option key={idx} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      ) : field.type === "textarea" ? (
                        <textarea
                          name={field.name}
                          id={field.name}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                          required={field.required}
                          onChange={handleChange}
                        />
                      ) : field.type === "checkbox" ? (
                        <input
                          type="checkbox"
                          name={field.name}
                          id={field.name}
                          className="h-4 w-4 text-primary-600 border-gray-300 rounded focus:ring-primary-500 dark:bg-gray-700 dark:border-gray-600"
                          checked={formData[field.name]}
                          onChange={handleChange}
                        />
                      ) : (
                        <input
                          type={field.type}
                          name={field.name}
                          id={field.name}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                          required={field.required}
                          value={formData[field.name]}
                          onChange={handleChange}
                        />
                      )}
                    </div>
                  ))}
                </>
              )}
              <div className="col-span-full">
                <button
                  type="submit"
                  className="w-full text-white bg-gray-900 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                >
                  Apply Now
                </button>
              </div>
              <p className="text-sm font-light text-gray-500 dark:text-gray-400 col-span-full text-center mt-4">
                Back to Career?{" "}
                <a
                  href="https://majumotorgroup.com/career/"
                  className="font-medium text-primary-600 hover:underline dark:text-primary-500"
                >
                  Click here
                </a>
              </p>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RegistrationForm;
