import React from "react";

const CandidateRE = ({
  expanded,
  toggleSection,
  references,
  handleReferenceChange,
  handleAddReference,
  handleRemoveReference,
}) => {
  return (
    <>
      <h2
        className="text-blue-800 col-span-2 text-lg font-semibold dark:text-white cursor-pointer"
        onClick={() => toggleSection("reference")}
      >
        References
        <hr className="my-4 border-gray-300 col-span-2" />
      </h2>
      {expanded && (
        <>
          {references.map((reference, index) => (
            <div key={index} className="border-b border-gray-300 pb-4 mb-4">
              <h3 className="text-md font-semibold text-gray-700 dark:text-white">
                Reference {index + 1}
              </h3>

              {[
                { label: "Name", type: "text", name: "name" },
                { label: "Company", type: "text", name: "company" },
                { label: "Position", type: "text", name: "position" },
                {
                  label: "Relationship",
                  type: "select",
                  name: "relationship",
                  options: [
                    { value: "", label: "(not specified)" },
                    { value: "Former Supervisor", label: "Former Supervisor" },
                    { value: "Former Manager", label: "Former Manager" },
                    { value: "Former Director", label: "Former Director" },
                    { value: "Partner", label: "Partner" },
                    { value: "Other", label: "Other" },
                  ],
                },
                { label: "Phone", type: "text", name: "phone" },
              ].map((field, fieldIndex) => (
                <div key={fieldIndex} className="p-2 col-span-1">
                  <label
                    htmlFor={`${field.name}-${index}`}
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    {field.label}
                  </label>
                  {field.type === "select" ? (
                    <select
                      name={field.name}
                      id={`${field.name}-${index}`}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      value={reference[field.name]}
                      onChange={(e) =>
                        handleReferenceChange(index, field.name, e.target.value)
                      }
                    >
                      {field.options.map((option, optionIndex) => (
                        <option key={optionIndex} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <input
                      type={field.type}
                      name={field.name}
                      id={`${field.name}-${index}`}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      value={reference[field.name]}
                      onChange={(e) =>
                        handleReferenceChange(index, field.name, e.target.value)
                      }
                    />
                  )}
                </div>
              ))}
              <button
                type="button"
                onClick={() => handleRemoveReference(index)}
                className="text-red-500 text-sm underline mt-2"
              >
                Remove Reference
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={handleAddReference}
            className="mt-4 bg-blue-500 text-white px-4 py-2 rounded max-h-10"
          >
            Add Another Reference
          </button>
        </>
      )}
    </>
  );
};

export default CandidateRE;