import React, { useEffect, useState } from "react";
import CandidateCitizenship from "./Candidate/CandidateCitizenship";
import CandidatePI from "./Candidate/CandidatePI";
import CandidateHA from "./Candidate/CandidateHA";
import CandidateSA from "./Candidate/CandidateSA";
import CandidateCT from "./Candidate/CandidateCT";
import CandidateEC from "./Candidate/CandidateEC";
import CandidateDP from "./Candidate/CandidateDP";
import CandidateHE from "./Candidate/CandidateHE";
import CandidateLA from "./Candidate/CandidateLA";
import CandidateSE from "./Candidate/CandidateSE";
import CandidatePC from "./Candidate/CandidatePC";
import CandidateEW from "./Candidate/CandidateEW";
import CandidateRE from "./Candidate/CandidateRE";

const CandidateForm = () => {
  const [formData, setFormData] = useState({
    candidateId: "",
    candidateName: "",
    busCode: "",
    business: "",
    subBusCode: "",
    subBusiness: "",
    jpNumber: "",
    position: "",
    citizenCode: "",
    nationalityCode: "",
    homeAdr: "",
    homeRegion: "",
    homeCountry: "",
    homeCity: "",
    homeZip: "",
    homeState: "",
    primaryEmail: "",
    privatePhone: "",
    privateFax: "",
    privateMobile: "",
    gender: "",
    birthDate: "",
    birthPlace: "",
    shortName: "",
    secondAdr: "",
    secondRegion: "",
    secondCountry: "",
    secondCity: "",
    secondZip: "",
    secondState: "",
    homeStatus: "",
    religion: "",
    bloodType: "",
    height: "",
    weight: "",
    idNo: "",
    idExpDate: "",
    driverLicenseNo: "",
    driverLicenseDate: "",
    maritalStatus: "",
    emergencyContactName: "",
    emergencyRelationship: "",
    emergencyAddress: "",
    emergencyPhone: "",
    workAchievement: "",
    latestSalary: "",
    expectedSalary: "",
    idType: "",
  });

  const [cityOptions, setCityOptions] = useState([]);
  const [citizenshipOptions, setCitizenshipOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [filteredCityOptions, setFilteredCityOptions] = useState([]);
  const [filteredCityOptionsSecondary, setFilteredCityOptionsSecondary] =
    useState([]);
  const [filteredCountryOptions, setFilteredCountryOptions] = useState([]);
  const [filteredCountryOptionsSecondary, setFilteredCountryOptionsSecondary] =
    useState([]);
  const [filteredProfSubTypeOptions, setFilteredProfSubTypeOptions] = useState(
    []
  );
  const [filteredStateOptions, setFilteredStateOptions] = useState([]);
  const [filteredStateOptionsSecondary, setFilteredStateOptionsSecondary] =
    useState([]);
  const [family, setFamily] = useState([]);
  const [higherEducations, setHigherEducations] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [languageOptions, setLanguageOptions] = useState([]);
  const [levelOptions, setLevelOptions] = useState([]);
  const [nationalityOptions, setNationalityOptions] = useState([]);
  const [professionalCredentials, setProfessionalCredentials] = useState([]);
  const [professionalSubTypeOptions, setProfessionalSubTypeOptions] = useState(
    []
  );
  const [professionalTypeOptions, setProfessionalTypeOptions] = useState([]);
  const [regionOptions, setRegionOptions] = useState([]);
  const [responsibilityAreaOptions, setResponsibilityAreaOptions] = useState(
    []
  );
  const [secondaryEducation, setSecondaryEducation] = useState([]);
  // eslint-disable-next-line
  const [selectedCountry, setSelectedCountry] = useState("");
  // eslint-disable-next-line
  const [selectedCountrySecondary, setSelectedCountrySecondary] = useState("");
  // eslint-disable-next-line
  const [selectedRegion, setSelectedRegion] = useState("");
  // eslint-disable-next-line
  const [selectedRegionSecondary, setSelectedRegionSecondary] = useState("");
  const [stateOptions, setStateOptions] = useState([]);
  const [subjectOptions, setSubjectOptions] = useState([]);
  const [workHistory, setWorkHistory] = useState([]);
  const [references, setReferences] = useState([]);

  const [expandedSections, setExpandedSections] = useState({
    personalInfo: true,
    homeAddress: false,
    secondaryAddress: false,
    contact: false,
    emergencyContact: false,
    citizenship: false,
    family: false,
    higherEducation: false,
    secondaryEducation: false,
    professionalCredentials: false,
    workHistory: false,
    languages: false,
    reference: false,
  });
  useEffect(() => {
    const jpnumber = localStorage.getItem("jpnumber");
    console.log("jpnumber", jpnumber);
    // fetch(
    //   `https://api.sinergiteknologi.co.id/mmgwebapi/get/joblists?JPNumber=${jpnumber}`
    // )
    //   .then((response) => {
    //     if (!response.ok) {
    //       throw new Error(`HTTP error! status: ${response.status}`);
    //     }
    //     return response.json();
    //   })
    //   .then((data) => {
    //     if (data.length === 0) {
    //       console.error("No data found");
    //       return;
    //     }
    //     setFormData({
    //       busCode: data[0].BusCode || "",
    //       subBusCode: data[0].SubBusCode || "",
    //       business: data[0].Business || "",
    //       subBusiness: data[0].SubBusiness || "",
    //       jpNumber: data[0].JPNumber || "",
    //       position: data[0].Position || "",
    //     });
    //   })
    //   .catch((error) => {
    //     console.error("Fetch error:", error);
    //   });

    // Fetch untuk mendapatkan data berdasarkan jpnumber
    fetch(
      `https://api.sinergiteknologi.co.id/mmgwebapi/get/joblists?JPNumber=${jpnumber}`
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.length === 0) {
          console.error("No data found");
          return;
        }

        // Ambil BusCode dari data
        const busCode = data[0].BusCode || "";
        console.log("BusCode:", busCode);
        setFormData({
          busCode: data[0].BusCode || "",
          subBusCode: data[0].SubBusCode || "",
          business: data[0].Business || "",
          subBusiness: data[0].SubBusiness || "",
          jpNumber: data[0].JPNumber || "",
          position: data[0].Position || "",
        });
        
        // Fetch untuk menghasilkan CandidateID berdasarkan BusCode
        return fetch(
          `https://api.sinergiteknologi.co.id/mmgwebapi/get/candidateid?busCode=${busCode}`
        );
      })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((candidateData) => {
        // Tampilkan atau gunakan CandidateID yang dihasilkan
        console.log("Generated CandidateID:", candidateData.CandidateID);

        // Jika Anda ingin menyimpan CandidateID ke dalam state atau form data
        setFormData((prevData) => ({
          ...prevData,
          candidateId: candidateData.CandidateID || "",
        }));
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });

    const fetchOptions = async (url, setter, mapFunc) => {
      try {
        const response = await fetch(url);
        const data = await response.json();
        if (Array.isArray(data)) {
          setter(mapFunc(data));
        } else {
          console.error(`Unexpected response structure from ${url}:`, data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchOptions(
      "https://api.sinergiteknologi.co.id/mmgwebapi/get/Nationality",
      setNationalityOptions,
      (data) =>
        data.map((item) => ({
          value: item.NationalityCode,
          label: item.NationalityName,
        }))
    );
    fetchOptions(
      "https://api.sinergiteknologi.co.id/mmgwebapi/get/Citizenship",
      setCitizenshipOptions,
      (data) =>
        data.map((item) => ({
          value: item.CitizenCode,
          label: item.CitizenName,
        }))
    );
    fetchOptions(
      "https://api.sinergiteknologi.co.id/mmgwebapi/get/Region",
      setRegionOptions,
      (data) =>
        data.map((item) => ({ value: item.RegionCode, label: item.RegionName }))
    );
    fetchOptions(
      "https://api.sinergiteknologi.co.id/mmgwebapi/get/Country",
      setCountryOptions,
      (data) =>
        data.map((item) => ({
          value: item.CountryCode,
          label: item.CountryName,
          regionCode: item.RegionCode,
        }))
    );
    fetchOptions(
      "https://api.sinergiteknologi.co.id/mmgwebapi/get/City",
      setCityOptions,
      (data) =>
        data.map((item) => ({
          value: item.CityCode,
          label: item.CityName,
          countryCode: item.CountryCode,
        }))
    );
    fetchOptions(
      "https://api.sinergiteknologi.co.id/mmgwebapi/get/State",
      setStateOptions,
      (data) =>
        data.map((item) => ({
          value: item.StateCode,
          label: item.StateName,
          countryCode: item.CountryCode,
        }))
    );
    fetchOptions(
      "https://api.sinergiteknologi.co.id/mmgwebapi/get/EduSubject",
      setSubjectOptions,
      (data) =>
        data.map((item) => ({
          value: item.EducationSubjectCode,
          label: item.EducationSubjectName,
        }))
    );
    fetchOptions(
      "https://api.sinergiteknologi.co.id/mmgwebapi/get/EduLevel",
      setLevelOptions,
      (data) =>
        data.map((item) => ({
          value: item.EducationLevelCode,
          label: item.EducationLevelName,
        }))
    );
    fetchOptions(
      "https://api.sinergiteknologi.co.id/mmgwebapi/get/proftype",
      setProfessionalTypeOptions,
      (data) =>
        data.map((item) => ({
          value: item.ProfessionalTypeCode,
          label: item.ProfessionalTypeName,
        }))
    );
    fetchOptions(
      "https://api.sinergiteknologi.co.id/mmgwebapi/get/profsubtype",
      setProfessionalSubTypeOptions,
      (data) =>
        data.map((item) => ({
          value: item.ProfessionalSubTypeCode,
          label: item.ProfessionalSubTypeName,
          professionalTypeCode: item.ProfessionalTypeCode,
        }))
    );
    fetchOptions(
      "https://api.sinergiteknologi.co.id/mmgwebapi/get/responsibilityarea",
      setResponsibilityAreaOptions,
      (data) =>
        data.map((item) => ({
          value: item.ResponsibilityAreaCode,
          label: item.ResponsibilityAreaName,
        }))
    );
    fetchOptions(
      "https://api.sinergiteknologi.co.id/mmgwebapi/get/language",
      setLanguageOptions,
      (data) =>
        data.map((item) => ({
          value: item.LanguageCode,
          label: item.LanguageName,
        }))
    );
  }, []);

  const handleRegionChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedRegion(selectedValue);

    if (selectedValue) {
      const filteredCountries = countryOptions.filter(
        (country) => country.regionCode === selectedValue
      );
      setFilteredCountryOptions(filteredCountries);
      setSelectedCountry(""); // Reset country selection
      setFilteredCityOptions([]); // Reset city selection
      setFilteredStateOptions([]); // Reset state selection
      setFormData((prevData) => ({
        ...prevData,
        homeRegion: selectedValue, // Update homeRegion
      }));
    } else {
      setFilteredCountryOptions(countryOptions); // Show all countries if no region is selected
    }
  };
  const handleCountryChange = (event) => {
    const selectedValue = event.target.value;
    console.log("CountryChange:", selectedValue);
    setSelectedCountry(selectedValue);

    if (selectedValue) {
      const filteredCities = cityOptions.filter(
        (city) => city.countryCode === selectedValue
      );
      const filteredStates = stateOptions.filter(
        (state) => state.countryCode === selectedValue
      );
      setFilteredCityOptions(filteredCities);
      setFilteredStateOptions(filteredStates);
      setFormData((prevData) => ({
        ...prevData,
        homeCountry: selectedValue, // Update homeCountry
      }));
    } else {
      setFilteredCityOptions([]);
      setFilteredStateOptions([]);
    }
  };
  const handleRegionSecondaryChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedRegionSecondary(selectedValue);

    if (selectedValue) {
      const filteredCountries = countryOptions.filter(
        (country) => country.regionCode === selectedValue
      );
      setFilteredCountryOptionsSecondary(filteredCountries);
      setSelectedCountrySecondary("");
      setFilteredCityOptionsSecondary([]);
      setFilteredStateOptionsSecondary([]);
      setFormData((prevData) => ({
        ...prevData,
        secondRegion: selectedValue, // Update secondRegion
      }));
    } else {
      setFilteredCountryOptionsSecondary(countryOptions);
    }
  };
  const handleCountrySecondaryChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedCountrySecondary(selectedValue);

    if (selectedValue) {
      const filteredCities = cityOptions.filter(
        (city) => city.countryCode === selectedValue
      );
      const filteredStates = stateOptions.filter(
        (state) => state.countryCode === selectedValue
      );
      setFilteredCityOptionsSecondary(filteredCities);
      setFilteredStateOptionsSecondary(filteredStates);
      setFormData((prevData) => ({
        ...prevData,
        secondCountry: selectedValue, // Update secondCountry
      }));
    } else {
      setFilteredCityOptionsSecondary([]);
      setFilteredStateOptionsSecondary([]);
    }
  };
  const handleAddEducation = () => {
    setHigherEducations([
      ...higherEducations,
      {
        startDate: "",
        endDate: "",
        institution: "",
        degree: "",
        subject: "",
        major: "",
        grade: "",
      },
    ]);
  };
  const handleRemoveEducation = (index) => {
    setHigherEducations(higherEducations.filter((_, i) => i !== index));
  };
  const handleEducationChange = (index, field, value) => {
    const updatedEducations = [...higherEducations];
    updatedEducations[index][field] = value;
    setHigherEducations(updatedEducations);
  };
  const handleAddFamilyMember = () => {
    setFamily([
      ...family,
      {
        familyName: "",
        relatiofamilyRelationshipnship: "",
        familyGender: "",
        familyBirthPlace: "",
        familyBirthDate: "",
        familyPhone: "",
        familyAdr: "",
        familyEdu: "",
        familyOccupation: "",
      },
    ]);
  };
  const handleFamilyMemberChange = (index, name, value) => {
    const updatedFamily = family.map((member, idx) => {
      if (idx === index) {
        return { ...member, [name]: value }; // Update the specific member
      }
      return member;
    });
    setFamily(updatedFamily); // Update the state
  };
  const handleRemoveFamilyMember = (index) => {
    const updatedFamily = family.filter((_, idx) => idx !== index);
    setFamily(updatedFamily); // Update the state
  };
  const handleLanguageChange = (index, field, value) => {
    const updatedLanguages = [...languages];
    updatedLanguages[index] = {
      ...updatedLanguages[index],
      [field]: value,
    };
    setLanguages(updatedLanguages);
  };
  const handleAddLanguage = () => {
    setLanguages([...languages, { language: "", proficiency: "" }]);
  };
  const handleRemoveLanguage = (index) => {
    const updatedLanguages = languages.filter((_, i) => i !== index);
    setLanguages(updatedLanguages);
  };
  const handleAddSecondaryEducation = () => {
    setSecondaryEducation([
      ...secondaryEducation,
      {
        school: "",
        location: "",
        levelType: "",
        completed: false,
        completedDate: "",
        notes: "",
      },
    ]);
  };
  const handleSecondaryEducationChange = (index, name, value) => {
    const updatedEducation = secondaryEducation.map((education, idx) => {
      if (idx === index) {
        return { ...education, [name]: value };
      }
      return education;
    });
    setSecondaryEducation(updatedEducation);
  };
  const handleRemoveSecondaryEducation = (index) => {
    const updatedEducation = secondaryEducation.filter(
      (_, idx) => idx !== index
    );
    setSecondaryEducation(updatedEducation);
  };
  const handleProfTypeChange = (selectedType) => {
    const filteredSubTypes = professionalSubTypeOptions.filter(
      (subType) => subType.professionalTypeCode === selectedType
    );
    setFilteredProfSubTypeOptions(filteredSubTypes);
  };
  const handleAddCredential = () => {
    setProfessionalCredentials([
      ...professionalCredentials,
      {
        startDate: "",
        endDate: "",
        title: "",
        professionalType: "",
        professionalSubType: "",
        institute: "",
        location: "",
        responsibilityArea: "",
        notes: "",
      },
    ]);
  };
  const handleCredentialChange = (index, field, value) => {
    const updatedCredentials = [...professionalCredentials];
    updatedCredentials[index][field] = value;

    // If changing professional type, filter sub-types
    if (field === "professionalType") {
      handleProfTypeChange(value);
    }

    setProfessionalCredentials(updatedCredentials);
  };
  const handleRemoveCredential = (index) => {
    const updatedCredentials = [...professionalCredentials];
    updatedCredentials.splice(index, 1);
    setProfessionalCredentials(updatedCredentials);
  };
  const handleWorkHistoryChange = (index, field, value) => {
    const updatedHistory = [...workHistory];
    updatedHistory[index] = { ...updatedHistory[index], [field]: value };
    setWorkHistory(updatedHistory);
  };
  const handleAddWorkHistory = () => {
    setWorkHistory([...workHistory, {}]);
  };
  const handleRemoveWorkHistory = (index) => {
    const updatedHistory = [...workHistory];
    updatedHistory.splice(index, 1);
    setWorkHistory(updatedHistory);
  };
  const handleReferenceChange = (index, name, value) => {
    const updatedReferences = [...references];
    updatedReferences[index][name] = value;
    setReferences(updatedReferences);
  };
  const handleAddReference = () => {
    setReferences([...references, {}]);
  };
  const handleRemoveReference = (index) => {
    const updatedReferences = references.filter((_, i) => i !== index);
    setReferences(updatedReferences);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  const toggleSection = (section) => {
    setExpandedSections((prevSections) => ({
      ...prevSections,
      [section]: !prevSections[section],
    }));
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    const payload = {
      PI: {
        /*** Personal Information Fields ***/
        CandidateID: formData.candidateId,
        CandidateName: formData.candidateName,
        BusCode: formData.busCode,
        SubBusCode: formData.subBusCode,
        JPNumber: formData.jpNumber,
        Gender: formData.gender,
        BirthDate: formData.birthDate,
        BirthPlace: formData.birthPlace,
        ShortName: formData.shortName,
        HomeStatus: formData.homeStatus,
        Religion: formData.religion,
        BloodType: formData.bloodType,
        Height: formData.height,
        Weight: formData.weight,
        IDNo: formData.idNo,
        IDExpDate: formData.idExpDate,
        DriverLicenseNo: formData.driverLicenseNo,
        DriverLicenseDate: formData.driverLicenseDate,
        MatrialStatus: formData.matrialStatus,
        WorkAchievement: formData.workAchievement,
        LatestSalary: formData.latestSalary,
        ExpectedSalary: formData.expectedSalary,
        IDType: formData.idType,
        CitizenCode: formData.citizenship,
        NationalityCode: formData.nationality,
        HomeAdr: formData.homeAdr,
        HomeRegion: formData.homeRegion,
        HomeCountry: formData.homeCountry,
        HomeCity: formData.homeCity,
        HomeZIP: formData.homeZip,
        HomeState: formData.homeState,
        PrimaryEmail: formData.primaryEmail,
        PrivatePhone: formData.privatePhone,
        PrivateFax: formData.privateFax,
        PrivateMobile: formData.privateMobile,
        SecondAdr: formData.secondAdr,
        SecondRegion: formData.secondRegion,
        SecondCountry: formData.secondCountry,
        SecondCity: formData.secondCity,
        SecondZIP: formData.secondZip,
        SecondState: formData.secondState,
        EmergencyContactName: formData.emergencyContactName,
        EmergencyRelationship: formData.emergencyRelationship,
        EmergencyAddress: formData.emergencyAddress,
        EmergencyPhone: formData.emergencyPhone,
      },
      DP: {
        families: family.map((family, index) => ({
          Index: index,
          DependantName: family.familyName,
          DependantRelationship: family.familyRelationship,
          DependantGender: family.familyGender,
          DependantBirthPlace: family.familyBirthPlace,
          DependantBirthDate: family.familyBirthDate,
          DependantAdr: family.familyAdr,
          DependantPhn: family.familyPhone,
          DependantEducation: family.familyEdu,
          DependantOccupation: family.familyOccupation,
        })),
      },
      HE: {
        higherEducations: higherEducations.map((higherEducation, index) => ({
          EducationNo: index,
          StartDate: higherEducation.startDate,
          EndDate: higherEducation.endDate,
          Institution: higherEducation.institution,
          DegreeCode: higherEducation.degree,
          SubjectCode: higherEducation.subject,
          Major: higherEducation.major,
          Completed: higherEducation.completed,
          Grade: higherEducation.grade,
          Notes: higherEducation.notes,
        })),
      },
      SE: {
        secondEducations: secondaryEducation.map((se, index) => ({
          EducationNo: index,
          School: se.school,
          Location: se.location,
          LevelType: se.levelType,
          Completed: se.completed,
          CompletedDate: se.completedDate,
        })),
      },
      PC: {
        professionalCredentials: professionalCredentials.map((pc, index) => ({
          ProfessionalNo: index,
          StartDate: pc.startDate,
          EndDate: pc.endDate,
          Title: pc.title,
          ProfessionalTypeCode: pc.professionalType,
          ProfessionalSubTypeCode: pc.professionalSubType,
          Institute: pc.institute,
          Location: pc.location,
          ResponsibilityAreaCode: pc.responsibilityArea,
          Notes: pc.notes,
        })),
      },
      EW: {
        workHistories: workHistory.map((ew, index) => ({
          WorkNo: index,
          StartDate: ew.startDate,
          EndDate: ew.endDate,
          Position: ew.position,
          Company: ew.company,
          Address: ew.address,
          Phone: ew.phone,
          ResponsibilityAreaCode: ew.responsibilityArea,
          InternationalExp: ew.internationalExp,
          Salary: ew.salary,
          Reference: ew.reference,
          Responsibilities: ew.responsibilities,
          Notes: ew.notes,
        })),
      },
      LA: {
        languages: languages.map((language, index) => ({
          Index: index,
          LanguageCode: language.language,
          Proficiency: language.proficiency,
        })),
      },
      RE: {
        references: references.map((ref, index) => ({
          Index: index,
          RefName: ref.name,
          RefCompany: ref.company,
          RefPosition: ref.position,
          RefRelationship: ref.relationship,
          RefPhone: ref.phone,
        })),
      },
    };
    console.log("payload", payload);

    try {
      const response = await fetch(
        "https://api.sinergiteknologi.co.id/mmgwebapi/post/candidate",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("Success:", data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <section className="bg-gray-50 dark:bg-gray-900">
      <div className="flex flex-col items-center justify-center px-6 pb-8 mx-auto md:h-screen lg:py-0">
        <div
          className="w-full bg-white rounded-lg shadow dark:border md:mt-0 xl:p-0 dark:bg-gray-800 dark:border-gray-700"
          style={{ maxWidth: "1200px" }}
        >
          <div
            className="p-6 space-y-4 md:space-y-6 sm:p-8"
            style={{ maxHeight: "500px", overflowY: "auto" }}
          >
            <div className="flex flex-col items-center justify-center">
              <img className="h-24" src="/img/MMG.png" alt="logo" />
              <h1 className="text-2xl font-bold text-center leading-tight tracking-tight text-blue-900 md:text-2xl dark:text-white">
                Candidate Form
              </h1>
            </div>
            <form
              className="grid grid-cols-1 sm:grid-cols-2 gap-4"
              onSubmit={handleSubmit}
            >
              <CandidatePI
                formData={formData}
                handleChange={handleChange}
                expanded={expandedSections.personalInfo}
                toggleSection={toggleSection}
              />
              <CandidateHA
                expanded={expandedSections.homeAddress}
                toggleSection={toggleSection}
                regionOptions={regionOptions}
                filteredCountryOptions={filteredCountryOptions}
                filteredCityOptions={filteredCityOptions}
                filteredStateOptions={filteredStateOptions}
                formData={formData}
                handleRegionChange={handleRegionChange}
                handleCountryChange={handleCountryChange}
                handleChange={handleChange}
              />
              <CandidateSA
                expanded={expandedSections.secondaryAddress}
                toggleSection={toggleSection}
                regionOptions={regionOptions}
                filteredCountryOptionsSecondary={
                  filteredCountryOptionsSecondary
                }
                filteredCityOptionsSecondary={filteredCityOptionsSecondary}
                filteredStateOptionsSecondary={filteredStateOptionsSecondary}
                formData={formData}
                handleRegionSecondaryChange={handleRegionSecondaryChange}
                handleCountrySecondaryChange={handleCountrySecondaryChange}
                handleChange={handleChange}
              />
              <CandidateCT
                expanded={expandedSections.contact}
                toggleSection={toggleSection}
                formData={formData}
                handleChange={handleChange}
              />
              <CandidateEC
                expanded={expandedSections.emergencyContact}
                toggleSection={toggleSection}
                formData={formData}
                handleChange={handleChange}
              />
              <CandidateCitizenship
                expanded={expandedSections.citizenship}
                toggleSection={toggleSection}
                citizenshipOptions={citizenshipOptions}
                nationalityOptions={nationalityOptions}
                formData={formData}
                handleChange={handleChange}
              />
              <CandidateDP
                expanded={expandedSections.family}
                toggleSection={toggleSection}
                family={family}
                handleAddFamilyMember={handleAddFamilyMember}
                handleFamilyMemberChange={handleFamilyMemberChange}
                handleRemoveFamilyMember={handleRemoveFamilyMember}
              />
              <CandidateHE
                expanded={expandedSections.higherEducation}
                toggleSection={toggleSection}
                higherEducations={higherEducations}
                handleEducationChange={handleEducationChange}
                handleAddEducation={handleAddEducation}
                handleRemoveEducation={handleRemoveEducation}
                subjectOptions={subjectOptions}
              />
              <CandidateSE
                expanded={expandedSections.secondaryEducation}
                toggleSection={toggleSection}
                secondaryEducation={secondaryEducation}
                handleEducationChange={handleSecondaryEducationChange}
                handleAddEducation={handleAddSecondaryEducation}
                handleRemoveEducation={handleRemoveSecondaryEducation}
                levelOptions={levelOptions}
              />
              <CandidatePC
                expanded={expandedSections.professionalCredentials}
                toggleSection={toggleSection}
                professionalCredentials={professionalCredentials}
                handleCredentialChange={handleCredentialChange}
                handleAddCredential={handleAddCredential}
                handleRemoveCredential={handleRemoveCredential}
                professionalTypeOptions={professionalTypeOptions}
                professionalSubTypeOptions={professionalSubTypeOptions}
                filteredProfSubTypeOptions={filteredProfSubTypeOptions}
                responsibilityAreaOptions={responsibilityAreaOptions}
              />
              <CandidateEW
                expanded={expandedSections.workHistory}
                toggleSection={toggleSection}
                workHistory={workHistory}
                handleWorkHistoryChange={handleWorkHistoryChange}
                handleAddWorkHistory={handleAddWorkHistory}
                handleRemoveWorkHistory={handleRemoveWorkHistory}
                responsibilityAreaOptions={responsibilityAreaOptions}
              />
              <CandidateLA
                expanded={expandedSections.languages}
                toggleSection={toggleSection}
                languages={languages}
                handleLanguageChange={handleLanguageChange}
                handleAddLanguage={handleAddLanguage}
                handleRemoveLanguage={handleRemoveLanguage}
                languageOptions={languageOptions}
              />
              <CandidateRE
                expanded={expandedSections.reference}
                toggleSection={toggleSection}
                references={references}
                handleReferenceChange={handleReferenceChange}
                handleAddReference={handleAddReference}
                handleRemoveReference={handleRemoveReference}
              />
              <div className="col-span-full">
                <button
                  type="submit"
                  onClick={handleSubmit}
                  className="w-full text-white bg-gray-900 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                >
                  Apply Now
                </button>
              </div>
              <p className="text-sm font-light text-gray-500 dark:text-gray-400 col-span-full text-center mt-4">
                Back to Career?{" "}
                <a
                  href="https://majumotorgroup.com/career/"
                  className="font-medium text-primary-600 hover:underline dark:text-primary-500"
                >
                  Click here
                </a>
              </p>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CandidateForm;
