import React from "react";

const CandidateSA = ({
  expanded,
  toggleSection,
  regionOptions,
  filteredCountryOptionsSecondary,
  filteredCityOptionsSecondary,
  filteredStateOptionsSecondary,
  formData,
  handleRegionSecondaryChange,
  handleCountrySecondaryChange,
  handleChange,
}) => {
  return (
    <>
      <h2
        className="text-blue-800 col-span-2 text-lg font-semibold dark:text-white cursor-pointer"
        onClick={() => toggleSection("secondaryAddress")}
      >
        Secondary Address
        <hr className="my-4 border-gray-300 col-span-2" />
      </h2>
      {expanded && (
        <>
          {[
            {
              label: "Region",
              type: "select",
              name: "secondRegion",
              options: regionOptions,
              //required: true,
            },
            {
              label: "Country",
              type: "select",
              name: "secondCountry",
              options: filteredCountryOptionsSecondary,
              //required: true,
            },
            {
              label: "City ",
              type: "select",
              name: "secondCity",
              options: filteredCityOptionsSecondary,
              //required: true,
            },
            {
              label: "State",
              type: "select",
              name: "secondState",
              options: filteredStateOptionsSecondary,
              //required: true,
            },
            {
              label: "ZIP",
              type: "text",
              name: "secondZip",
              //required: true,
            },
            {
              label: "Address",
              type: "text",
              name: "secondAdr",
              //required: true,
            },
          ].map((field, index) => (
            <div
              key={index}
              className={`p-2 ${field.className || "col-span-1"}`}
            >
              <label
                htmlFor={field.name}
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                {field.label}
                {field.required && <span className="text-red-500">*</span>}
              </label>
              {field.type === "select" ? (
                <select
                  name={field.name}
                  id={field.name}
                  className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                    field.readOnly ? "bg-gray-200 cursor-not-allowed" : ""
                  }`}
                  required={field.required}
                  value={formData[field.name]}
                  onChange={
                    field.name === "secondRegion"
                      ? handleRegionSecondaryChange
                      : field.name === "secondCountry"
                      ? handleCountrySecondaryChange
                      : handleChange
                  }
                  disabled={field.readOnly}
                >
                  <option value="">Select {field.label}</option>
                  {field.options?.map((option, idx) => (
                    <option key={idx} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              ) : (
                <input
                  type={field.type}
                  name={field.name}
                  id={field.name}
                  className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                    field.readOnly ? "bg-gray-200 cursor-not-allowed" : ""
                  }`}
                  value={formData[field.name]}
                  onChange={handleChange}
                  required={field.required}
                  readOnly={field.readOnly}
                />
              )}
            </div>
          ))}
        </>
      )}
    </>
  );
};

export default CandidateSA;
