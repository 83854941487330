import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Link } from "react-router-dom";

const JobDetails = () => {
  const navigate = useNavigate();
  const [jobDetails, setJobDetails] = useState(null); // Initialize state for jobDetails

  useEffect(() => {
    const jpnumber = localStorage.getItem("jpnumber");
    const fetchJobDetails = async () => {
      try {
        const response = await axios.get(
          `https://api.sinergiteknologi.co.id/mmgwebapi/get/joblists?JPNumber=${jpnumber}`
        );
        // Assuming the response is an array, take the first element
        setJobDetails(response.data[0]);
        console.log("response: ", response.data[0]);
      } catch (error) {
        console.error("Error fetching job details:", error);
      }
    };
    fetchJobDetails();
  }, []);

  // Function to format the date
  const formatDate = (dateString) => {
    const options = { day: "numeric", month: "long", year: "numeric" };
    return new Date(dateString).toLocaleDateString("id-ID", options);
  };

  // Render loading state if jobDetails is not yet fetched
  if (!jobDetails) {
    return (
      <div>
        <div className="bg-white shadow-md rounded-lg p-6 max-w-lg mx-auto mt-8 mb-8">
          <h2 className="text-2xl font-bold text-gray-800 mb-2">
            Oops, We can't find the job you are looking for
          </h2>
          <p className="text-lg text-gray-500 mb-4">
            Try searching another jobs
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white px-80 my-8">
      <div>
        <div className="flex items-center">
          <div className="flex shadow-lg rounded-lg mr-4 p-4 ">
            <img
              src={`/img/logoMMG.png`}
              alt="Job Image"
              className="h-32 p-4 mx-8 my-4"
            />
          </div>
          <div>
            <h2 className="text-2xl font-bold text-gray-800 mb-8">
              {jobDetails.Position}
            </h2>
            <p className="text-lg text-black mb-2 flex items-center">
              <svg
                className="w-5 h-5 text-black dark:text-white mr-1"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  fillRule="evenodd"
                  d="M4 4a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2v14a1 1 0 1 1 0 2H5a1 1 0 1 1 0-2V5a1 1 0 0 1-1-1Zm5 2a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1H9Zm5 0a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1h-1Zm-5 4a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1H9Zm5 0a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1h-1Zm-3 4a2 2 0 0 0-2 2v3h2v-3h2v3h2v-3a2 2 0 0 0-2-2h-2Z"
                  clipRule="evenodd"
                />
              </svg>
              {jobDetails.SubBusiness}
            </p>
            <div className="text-black mb-4">
              <strong>Batas Lamaran:</strong>{" "}
              {formatDate(jobDetails.ClosingDate)}
            </div>
            <Link to="/candidateform">
              <button
                type="button"
                className="text-white bg-gray-900 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
              >
                Apply Now
              </button>
            </Link>
          </div>
        </div>
      </div>

      <hr className="my-4 border-gray-300" />
      <div className="mb-6">
        <h3 className="text-xl font-semibold text-gray-800 mb-2">
          Syarat dan Kualifikasi
        </h3>
        <ul className="list-disc list-inside text-gray-700">
          {/* Display MaxAge if greater than 0 */}
          {jobDetails.MaxAge > 0 && (
            <li>Usia Maks. {jobDetails.MaxAge} Tahun</li>
          )}

          {/* Display Experience if it's not empty */}
          {jobDetails.Experience && <li>{jobDetails.Experience}</li>}

          {/* Display Qualification if it's not empty */}
          {jobDetails.Qualification?.trim() &&
            jobDetails.Qualification.split("\n").map((qual, index) => (
              <li key={index}>{qual}</li>
            ))}
        </ul>
        {jobDetails.Location && (
          <h5 className="font-semibold text-gray-800 my-2">
            Penempatan: {jobDetails.Location}
          </h5>
        )}
      </div>

      <div className="flex justify-center my-8">
        <p className="text-gray-500 dark:text-gray-400 text-center">
          Kami mencari talenta baru untuk bergabung dalam perjalanan kami.{" "}
          <a
            href="https://majumotorgroup.com/career/"
            className="inline-flex items-center font-medium text-blue-600 dark:text-blue-500 hover:underline"
          >
            See Other Vacancy
            <svg
              className="w-4 h-4 ms-2 rtl:rotate-180"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 5h12m0 0L9 1m4 4L9 9"
              />
            </svg>
          </a>
        </p>
      </div>
    </div>
  );
};

export default JobDetails;
