import React, { useEffect, useState } from "react";
import { Card } from "flowbite-react";
import { Link } from "react-router-dom";

function JobListings() {
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await fetch(
          "https://api.sinergiteknologi.co.id/mmgwebapi/get/joblists",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
    
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
    
        const data = await response.json();
        if (Array.isArray(data)) {
          const formattedJobs = data.map((job) => ({
            title: job.Position,
            deadline: job.ClosingDate,
            jpnumber: job.JPNumber,
            busCode: job.BusCode,
            busName: job.Business,
            subBusCode: job.SubBusCode,
            subBusName: job.SubBusiness,
          }));
    
          console.log("formattedJobs: ", formattedJobs);
          setJobs(formattedJobs);
        } else {
          console.error("Unexpected data format:", data);
        }
      } catch (error) {
        console.error("Error fetching jobs:", error);
      }
    };

    fetchJobs();
  }, []);

  if (!jobs) {
    return (
      <div>
        <div className="bg-white shadow-md rounded-lg p-6 max-w-lg mx-auto mt-8 mb-8">
          <h2 className="text-2xl font-bold text-gray-800 mb-2">
            Oops, We can't find the job you are looking for
          </h2>
          <p className="text-lg text-gray-500 mb-4">
            Try searching another jobs
          </p>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="bg-gray-950 h-auto">
        <h1 className="text-4xl text-center text-white py-5">JOIN WITH US</h1>
      </div>
      <main className="flex-grow container mx-auto px-4 py-8">
        <div className="flex flex-wrap justify-center gap-6 px-4 py-6 lg:py-8">
          {jobs.map((job, index) => (
            <Card key={index} className="border border-black p-4">
              <div className="flex justify-center mb-4">
                <img src={`/img/logoMMG.png`} alt="logo MMG" className="h-20" />
              </div>
              <h5 className="text-lg font-bold tracking-tight text-gray-900 dark:text-white">
                {job.title}
              </h5>
              <p className="flex items-center text-sm font-normal text-gray-700 dark:text-gray-400">
                <svg
                  className="w-5 h-5 text-gray-800 dark:text-white mr-1"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 8v4l3 3m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
                Batas Lamaran:{" "}
                {new Date(job.deadline).toLocaleDateString("id-ID", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </p>
              <hr className="my-4 border-gray-300" />
              <div className="flex space-x-2">
                <Link
                  to="/job-details"
                  onClick={() => {
                    localStorage.setItem("jpnumber", job.jpnumber);
                  }}
                >
                  <button
                    type="button"
                    className="text-gray-900 bg-white border border-gray-600 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-full text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                  >
                    Learn More
                  </button>
                </Link>
                <Link
                  to="/candidateform"
                  onClick={() => {
                    localStorage.setItem("busCode", job.busCode);
                    localStorage.setItem("busName", job.busName);
                    localStorage.setItem("subBusCode", job.subBusCode);
                    localStorage.setItem("subBusName", job.subBusName);
                    localStorage.setItem("jpnumber", job.jpnumber);
                  }}
                >
                  <button
                    type="button"
                    className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
                  >
                    Apply Now
                  </button>
                </Link>
              </div>
            </Card>
          ))}
        </div>
      </main>
    </div>
  );
}

export default JobListings;
