import React from "react";

const CandidateEW = ({
  expanded,
  toggleSection,
  workHistory,
  handleWorkHistoryChange,
  handleAddWorkHistory,
  handleRemoveWorkHistory,
  responsibilityAreaOptions,
}) => {
  return (
    <>
      <h2
        className="text-blue-800 col-span-2 text-lg font-semibold dark:text-white cursor-pointer"
        onClick={() => toggleSection("workHistory")}
      >
        Work History
        <hr className="my-4 border-gray-300 col-span-2" />
      </h2>
      {expanded && (
        <>
          {workHistory.map((history, index) => (
            <div key={index} className="border-b border-gray-300 pb-4 mb-4">
              <h3 className="text-md font-semibold text-gray-700 dark:text-white">
                Work History {index + 1}
              </h3>

              {[
                {
                  label: "Start Date",
                  type: "date",
                  name: "startDate",
                  //required: true,
                },
                {
                  label: "End Date",
                  type: "date",
                  name: "endDate",
                  //required: true,
                },
                { label: "Position", 
                  type: "text", 
                  name: "position", 
                  //required: true 
                },
                { label: "Company", type: "text", name: "company", required: true 

                },
                { label: "Address", type: "text", name: "address", required: false 
                  
                },
                { label: "Phone", type: "text", name: "phone", required: false },
                {
                  label: "Area of Responsibility",
                  type: "dropdown",
                  name: "responsibilityArea",
                  options: responsibilityAreaOptions,
                },
                {
                  label: "International Experience",
                  type: "checkbox",
                  name: "internationalExp",
                  //required: false,
                },
                { label: "Reference", type: "text", name: "reference", 
                  //required: false 
                },
                { label: "Salary", type: "text", name: "salary", 
                  //required: false 

                },
                {
                  label: "Responsibilities",
                  type: "textarea",
                  name: "responsibilities",
                  //required: false,
                },
                { label: "Notes", type: "textarea", name: "notes", 
                  //required: false 

                },
              ].map((field, fieldIndex) => (
                <div key={fieldIndex} className="my-2">
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                    {field.label}
                  </label>
                  {field.type === "dropdown" ? (
                    <select
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      value={history[field.name] || ""}
                      onChange={(e) =>
                        handleWorkHistoryChange(index, field.name, e.target.value)
                      }
                    >
                      <option value="">Select {field.label}</option>
                      {(field.options || []).map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  ) : field.type === "textarea" ? (
                    <textarea
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      value={history[field.name] || ""}
                      onChange={(e) =>
                        handleWorkHistoryChange(index, field.name, e.target.value)
                      }
                    ></textarea>
                  ) : field.type === "checkbox" ? (
                    <input
                      type="checkbox"
                      className="mt-1"
                      checked={history[field.name] || false}
                      onChange={(e) =>
                        handleWorkHistoryChange(index, field.name, e.target.checked)
                      }
                    />
                  ) : (
                    <input
                      type={field.type}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      value={history[field.name] || ""}
                      onChange={(e) =>
                        handleWorkHistoryChange(index, field.name, e.target.value)
                      }
                    />
                  )}
                </div>
              ))}

              <button
                type="button"
                className="mt-2 text-red-600 hover:text-red-800"
                onClick={() => handleRemoveWorkHistory(index)}
              >
                Remove Work History
              </button>
            </div>
          ))}
          <button
            type="button"
            className="mt-4 bg-blue-500 text-white px-4 py-2 rounded max-h-10"
            onClick={handleAddWorkHistory}
          >
            Add Work History
          </button>
        </>
      )}
    </>
  );
};

export default CandidateEW;
