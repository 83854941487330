import React from "react";

const CandidatePI = ({ formData, handleChange, expanded, toggleSection }) => {
  return (
    <>
      <h2
        className="text-blue-800 col-span-2 text-lg font-semibold dark:text-white cursor-pointer"
        onClick={() => toggleSection("personalInfo")}
      >
        Personal Information
        <hr className="my-4 border-gray-300 col-span-2" />
      </h2>
      {expanded && (
        <>
          {[
            {
              label: "Candidate Name",
              type: "text",
              name: "candidateName",
              //required: true,
              className: "col-span-2",
            },
            {
              label: "Candidate ID",
              type: "text",
              name: "candidateId",
              readOnly: true,
            },
            {
              label: "Short Name",
              type: "text",
              name: "shortName",
            },
            {
              label: "Business",
              type: "text",
              name: "business",
              required: true,
              readOnly: true,
            },
            {
              label: "Sub Business",
              type: "text",
              name: "subBusiness",
              required: true,
              readOnly: true,
            },
            {
              label: "Job No.",
              type: "text",
              name: "jpNumber",
              required: true,
              readOnly: true,
            },
            {
              label: "Position",
              type: "text",
              name: "position",
              required: true,
              readOnly: true,
            },
            {
              label: "Birth Place",
              type: "text",
              name: "birthPlace",
              //required: true,
            },
            {
              label: "Birth Date",
              type: "date",
              name: "birthDate",
            },
            {
              label: "Gender",
              type: "select",
              name: "gender",
              options: [
                { value: "", label: "(not specified)" },
                { value: "Male", label: "Male" },
                { value: "Female", label: "Female" },
              ],
            },
            {
              label: "Blood Type",
              type: "select",
              name: "bloodType",
              options: [
                { value: "", label: "(not specified)" },
                { value: "O", label: "O" },
                { value: "A", label: "A" },
                { value: "B", label: "B" },
                { value: "AB", label: "AB" },
              ],
            },
            {
              label: "Religion",
              type: "select",
              name: "religion",
              options: [
                { value: "", label: "(not specified)" },
                { value: "Islam", label: "Islam" },
                { value: "Kristen", label: "Kristen" },
                { value: "Katolik", label: "Katolik" },
                { value: "Budha", label: "Budha" },
                { value: "Hindu", label: "Hindu" },
                { value: "Konghucu", label: "Konghucu" },
              ],
            },
            {
              label: "Matrial Status",
              type: "select",
              name: "matrialStatus",
              options: [
                { value: "", label: "(not specified)" },
                { value: "Civil union", label: "Civil union" },
                { value: "Divorced", label: "Divorced" },
                { value: "Married", label: "Married" },
                { value: "Unmarried", label: "Unmarried" },
                { value: "Widowed", label: "Widowed" },
              ],
            },
            {
              label: "Weight",
              type: "number",
              name: "weight",
              //required: true,
              min: 0,
            },
            {
              label: "Height",
              type: "number",
              name: "height",
              //required: true,
              min: 0,
            },
            {
              label: "Home Status",
              type: "select",
              name: "homeStatus",
              options: [
                { value: "", label: "(not specified)" },
                { value: "Milik Sendiri", label: "Milik Sendiri" },
                { value: "Sewa", label: "Sewa" },
                { value: "Keluarga", label: "Keluarga" },
              ],
            },
            {
              label: "ID Type",
              type: "select",
              name: "idType",
              options: [
                { value: "", label: "(not specified)" },
                { value: "KTP", label: "KTP" },
                { value: "SIM", label: "SIM" },
                { value: "Paspor", label: "Paspor" },
              ],
            },
            {
              label: "ID No",
              type: "text",
              name: "idNo",
              //required: true,
            },
            {
              label: "ID No Exp Date",
              type: "date",
              name: "idExpDate",
            },
            {
              label: "Driver License No",
              type: "text",
              name: "driverLicenseNo",
            },
            {
              label: "Exp Date",
              type: "date",
              name: "driverLicenseDate",
            },
            {
              label: "Achievement",
              type: "textarea",
              name: "workAchievement",
              className: "col-span-2",
            },
            {
              label: "Latest Salary",
              type: "number",
              name: "latestSalary",
              min: 0,
            },
            {
              label: "Expected Salary",
              type: "number",
              name: "expectedSalary",
              //required: true,
              min: 0,
            },
          ].map((field, index) => (
            <div
              key={index}
              className={`p-2 ${field.className || "col-span-1"}`}
            >
              <label
                htmlFor={field.name}
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                {field.label}
                {field.required && <span className="text-red-500">*</span>}{" "}
              </label>
              {field.type === "select" ? (
                <select
                  id={field.name}
                  name={field.name}
                  value={formData[field.name]}
                  onChange={handleChange}
                  required={field.required}
                  className={`block w-full p-2 border border-gray-300 rounded ${
                    field.readOnly ? "bg-gray-200" : ""
                  }`}
                  disabled={field.readOnly} // Disable the select if readOnly
                >
                  {field.options.map((option, idx) => (
                    <option key={idx} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              ) : field.type === "textarea" ? (
                <textarea
                  id={field.name}
                  name={field.name}
                  value={formData[field.name]}
                  onChange={handleChange}
                  className={`block w-full p-2 border border-gray-300 rounded ${
                    field.readOnly ? "bg-gray-200" : ""
                  }`}
                  readOnly={field.readOnly} // Make textarea readOnly
                />
              ) : (
                <input
                  id={field.name}
                  name={field.name}
                  type={field.type}
                  value={formData[field.name]}
                  onChange={handleChange}
                  required={field.required}
                  className={`block w-full p-2 border border-gray-300 rounded ${
                    field.readOnly ? "bg-gray-200" : ""
                  }`}
                  min={field.min}
                  readOnly={field.readOnly} // Set input to readOnly
                />
              )}
            </div>
          ))}
        </>
      )}
    </>
  );
};

export default CandidatePI;
