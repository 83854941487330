import React from "react";

const CandidateEC = ({ expanded, toggleSection, formData, handleChange }) => {
  return (
    <>
      <h2
        className="text-blue-800 col-span-2 text-lg font-semibold dark:text-white cursor-pointer"
        onClick={() => toggleSection("emergencyContact")}
      >
        Emergency Contact
        <hr className="my-4 border-gray-300 col-span-2" />
      </h2>
      {expanded && (
        <>
          {[
            {
              label: "Name",
              type: "text",
              name: "emergencyContactName",
              //required: true,
            },
            {
              label: "Relationship",
              type: "select",
              name: "emergencyRelationship",
              options: [
                { value: "", label: "(not specified)" },
                { value: "Former Supervisor", label: "Former Supervisor" },
                { value: "Former Manager", label: "Former Manager" },
                { value: "Former Director", label: "Former Director" },
                { value: "Partner", label: "Partner" },
                { value: "Hindu", label: "Hindu" },
                { value: "Other", label: "Other" },
              ],
            },
            {
              label: "Phone",
              type: "tel",
              name: "emergencyPhone",
              //required: true,
            },
            {
              label: "Address",
              type: "text",
              name: "emergencyAddress",
              //required: true,
            },
          ].map((field, index) => (
            <div
              key={index}
              className={`p-2 ${field.className || "col-span-1"}`}
            >
              <label
                htmlFor={field.name}
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                {field.label}
                {field.required && <span className="text-red-500">*</span>}{" "}
              </label>
              {field.type === "select" ? (
                <select
                  id={field.name}
                  name={field.name}
                  value={formData[field.name]}
                  onChange={handleChange}
                  required={field.required}
                  className="block w-full p-2 border border-gray-300 rounded"
                >
                  {field.options.map((option, idx) => (
                    <option key={idx} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              ) : field.type === "textarea" ? (
                <textarea
                  id={field.name}
                  name={field.name}
                  value={formData[field.name]}
                  onChange={handleChange}
                  className="block w-full p-2 border border-gray-300 rounded"
                />
              ) : (
                <input
                  id={field.name}
                  name={field.name}
                  type={field.type}
                  value={formData[field.name]}
                  onChange={handleChange}
                  required={field.required}
                  className="block w-full p-2 border border-gray-300 rounded"
                  min={field.min}
                  readOnly={field.readOnly}
                />
              )}
            </div>
          ))}
        </>
      )}
    </>
  );
};

export default CandidateEC;
