import React from "react";

const CandidatePC = ({
  expanded,
  toggleSection,
  professionalCredentials,
  handleCredentialChange,
  handleAddCredential,
  handleRemoveCredential,
  professionalTypeOptions,
  professionalSubTypeOptions,
  filteredProfSubTypeOptions,
  responsibilityAreaOptions,
}) => {
  return (
    <>
      <h2
        className="text-blue-800 col-span-2 text-lg font-semibold dark:text-white cursor-pointer"
        onClick={() => toggleSection("professionalCredentials")}
      >
        Professional Credentials
        <hr className="my-4 border-gray-300 col-span-2" />
      </h2>
      {expanded && (
        <>
          {professionalCredentials.map((credential, index) => (
            <div key={index} className="border-b border-gray-300 pb-4 mb-4">
              <h3 className="text-md font-semibold text-gray-700 dark:text-white">
                Credential {index + 1}
              </h3>

              {[
                {
                  label: "Start Date",
                  type: "date",
                  name: "startDate",
                  //required: true,
                },
                {
                  label: "End Date",
                  type: "date",
                  name: "endDate",
                  //required: true,
                },
                { label: "Title", type: "text", name: "title", required: true },
                {
                  label: "Professional Type",
                  type: "dropdown",
                  name: "professionalType",
                  options: professionalTypeOptions,
                },
                {
                  label: "Professional Sub Type",
                  type: "dropdown",
                  name: "professionalSubType",
                  dependsOn: "professionalType",
                  options: filteredProfSubTypeOptions,
                },
                {
                  label: "Institute",
                  type: "text",
                  name: "institute",
                  //required: true,
                },
                {
                  label: "Location",
                  type: "text",
                  name: "location",
                  //required: false,
                },
                {
                  label: "Area of Responsibility",
                  type: "dropdown",
                  name: "responsibilityArea",
                  options: responsibilityAreaOptions,
                },
                {
                  label: "Notes",
                  type: "textarea",
                  name: "notes",
                  //required: false,
                },
              ].map((field, fieldIndex) => (
                <div key={fieldIndex} className="my-2">
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                    {field.label}
                  </label>
                  {field.type === "dropdown" ? (
                    <select
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      value={credential[field.name] || ""}
                      onChange={(e) =>
                        handleCredentialChange(
                          index,
                          field.name,
                          e.target.value
                        )
                      }
                    >
                      <option value="">Select {field.label}</option>
                      {(field.options || []).map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  ) : field.type === "textarea" ? (
                    <textarea
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      value={credential[field.name] || ""}
                      onChange={(e) =>
                        handleCredentialChange(
                          index,
                          field.name,
                          e.target.value
                        )
                      }
                    ></textarea>
                  ) : (
                    <input
                      type={field.type}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      value={credential[field.name] || ""}
                      onChange={(e) =>
                        handleCredentialChange(
                          index,
                          field.name,
                          e.target.value
                        )
                      }
                    />
                  )}
                </div>
              ))}

              <button
                type="button"
                className="mt-2 text-red-600 hover:text-red-800"
                onClick={() => handleRemoveCredential(index)}
              >
                Remove Credential
              </button>
            </div>
          ))}
          <button
            type="button"
            className="mt-4 bg-blue-500 text-white px-4 py-2 rounded max-h-10"
            onClick={handleAddCredential}
          >
            Add Credential
          </button>
        </>
      )}
    </>
  );
};

export default CandidatePC;
