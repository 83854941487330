import "flowbite/dist/flowbite.min.css"; // Impor Flowbite CSS
import React from "react";
import { Route, Routes } from "react-router-dom";
import Header from "./components/Header.jsx";
import Footer from "./components/Footer.jsx";
import JobListings from "./components/JobListings.jsx";
import JobDetails from "./pages/JobDetails.jsx";
import Registration from "./pages/Registration.jsx";
import CandidateForm from "./components/CandidateForm.jsx";

function App() {
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <Routes>
        <Route path="/" element={<JobListings />} />
        <Route path="/job-details" element={<JobDetails />} />
        <Route path="/registration" element={<Registration />} />
        <Route path="/candidateform" element={<CandidateForm/>} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
