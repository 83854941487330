import React from "react";

const CandidateDP = ({ expanded, toggleSection, family, handleAddFamilyMember, handleFamilyMemberChange, handleRemoveFamilyMember, formData, handleChange }) => {
  return (
    <>
      <h2
        className="text-blue-800 col-span-2 text-lg font-semibold dark:text-white cursor-pointer"
        onClick={() => toggleSection("family")}
      >
        Family
        <hr className="my-4 border-gray-300 col-span-2" />
      </h2>
      {expanded && (
        <>
          {family.map((member, index) => (
            <div key={index} className={`border-b border-gray-300 pb-4 mb-4`}>
              <h3 className="text-md font-semibold text-gray-700 dark:text-white">
                Family Member {index + 1}
              </h3>
              {[
                { label: "Name", type: "text", name: "familyName", required: true },
                {
                  label: "Relationship",
                  type: "select",
                  name: "familyRelationship",
                  options: [
                    { value: "", label: "(not specified)" },
                    { value: "Spouse", label: "Spouse" },
                    { value: "Child", label: "Child" },
                    { value: "Parent", label: "Parent" },
                    { value: "Other", label: "Other" },
                  ],
                  //required: true,
                },
                {
                  label: "Gender",
                  type: "select",
                  name: "familyGender",
                  options: [
                    { value: "", label: "(not specified)" },
                    { value: "Male", label: "Male" },
                    { value: "Female", label: "Female" },
                  ],
                  //required: true,
                },
                {
                  label: "Birth Place",
                  type: "text",
                  name: "familyBirthPlace",
                  //required: true,
                },
                {
                  label: "Birth Date",
                  type: "date",
                  name: "familyBirthDate",
                  //required: true,
                },
                { label: "Phone", type: "text", name: "phone", required: true },
                {
                  label: "Address",
                  type: "text",
                  name: "familyAdr",
                  //required: true,
                  className: "col-span-2",
                },
                {
                  label: "Education",
                  type: "text",
                  name: "familyEdu",
                  //required: false,
                },
                {
                  label: "Occupation",
                  type: "text",
                  name: "familyOccupation",
                  //required: false,
                },
              ].map((field, fieldIndex) => (
                <div
                  key={fieldIndex}
                  className={`p-2 ${field.className || "col-span-1"}`}
                >
                  <label
                    htmlFor={field.name}
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    {field.label}
                    {field.required && <span className="text-red-500">*</span>}
                  </label>
                  {field.type === "select" ? (
                    <select
                      name={field.name}
                      id={field.name}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      required={field.required}
                      value={member[field.name]} // Set the value from member
                      onChange={(e) =>
                        handleFamilyMemberChange(
                          index,
                          field.name,
                          e.target.value
                        )
                      } // Update on change
                    >
                      {field.options.map((option, idx) => (
                        <option key={idx} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <input
                      type={field.type}
                      name={field.name}
                      id={field.name}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      required={field.required}
                      value={member[field.name]} // Set the value from member
                      onChange={(e) =>
                        handleFamilyMemberChange(
                          index,
                          field.name,
                          e.target.value
                        )
                      } // Update on change
                    />
                  )}
                </div>
              ))}
              <button
                type="button"
                onClick={() => handleRemoveFamilyMember(index)} // Call remove function
                className="mt-2 text-red-600 hover:text-red-800"
              >
                Remove Family Member
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={handleAddFamilyMember} // Call add function
            className="mt-4 bg-blue-500 text-white px-4 py-2 rounded max-h-10"
          >
            Add Another Family Member
          </button>
        </>
      )}
    </>
  );
};

export default CandidateDP;
