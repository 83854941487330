import React from "react";

const CandidateSE = ({
  expanded,
  toggleSection,
  secondaryEducation,
  handleEducationChange,
  handleAddEducation,
  handleRemoveEducation,
  levelOptions,
}) => {
  return (
    <>
      <h2
        className="text-blue-800 col-span-2 text-lg font-semibold dark:text-white cursor-pointer"
        onClick={() => toggleSection("secondaryEducation")}
      >
        Secondary Education
        <hr className="my-4 border-gray-300 col-span-2" />
      </h2>
      {expanded && (
        <>
          {secondaryEducation.map((education, index) => (
            <div key={index} className="border-b border-gray-300 pb-4 mb-4">
              <h3 className="text-md font-semibold text-gray-700 dark:text-white">
                Education {index + 1}
              </h3>

              {[
                {
                  label: "School",
                  type: "text",
                  name: "school",
                  //required: true,
                },
                {
                  label: "Location",
                  type: "text",
                  name: "location",
                  //required: true,
                },
                {
                  label: "Level",
                  type: "select",
                  name: "levelType",
                  options: levelOptions,
                  //required: true,
                },
              ].map((field, fieldIndex) => (
                <div key={fieldIndex} className="p-2 col-span-1">
                  <label
                    htmlFor={`${field.name}-${index}`}
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    {field.label}
                    {field.required && <span className="text-red-500">*</span>}
                  </label>
                  {field.type === "select" ? (
                    <select
                      name={field.name}
                      id={`${field.name}-${index}`}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      required={field.required}
                      value={education[field.name]}
                      onChange={(e) =>
                        handleEducationChange(index, field.name, e.target.value)
                      }
                    >
                      {field.options.map((option, optionIndex) => (
                        <option key={optionIndex} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <input
                      type={field.type}
                      name={field.name}
                      id={`${field.name}-${index}`}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      required={field.required}
                      value={education[field.name]}
                      onChange={(e) =>
                        handleEducationChange(index, field.name, e.target.value)
                      }
                    />
                  )}
                </div>
              ))}

              <div className="flex space-x-4 p-2">
                <div className="flex flex-col items-start">
                  <label
                    htmlFor={`completed-${index}`}
                    className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Completed
                  </label>
                  <input
                    type="checkbox"
                    name="completed"
                    id={`completed-${index}`}
                    className="h-4 w-4 text-primary-600 border-gray-300 rounded focus:ring-primary-500 dark:bg-gray-700 dark:border-gray-600"
                    checked={education.completed}
                    onChange={(e) =>
                      handleEducationChange(
                        index,
                        "completed",
                        e.target.checked
                      )
                    }
                  />
                </div>
                <div className="flex flex-col items-start">
                  <label
                    htmlFor={`completedOn-${index}`}
                    className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Completed On
                  </label>
                  <input
                    type="date"
                    name="completedDate"
                    id={`completedOn-${index}`}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={education.completedOn}
                    onChange={(e) =>
                      handleEducationChange(
                        index,
                        "completedDate",
                        e.target.value
                      )
                    }
                  />
                </div>
              </div>

              {/* Notes field */}
              <div className="p-2 col-span-1">
                <label
                  htmlFor={`notes-${index}`}
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Notes
                </label>
                <textarea
                  name="notes"
                  id={`notes-${index}`}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                  value={education.notes}
                  onChange={(e) =>
                    handleEducationChange(index, "notes", e.target.value)
                  }
                />
              </div>

              <button
                type="button"
                onClick={() => handleRemoveEducation(index)}
                className="mt-2 text-red-600 hover:text-red-800"
              >
                Remove
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={handleAddEducation}
            className="mt-4 bg-blue-500 text-white px-4 py-2 rounded max-h-10"
          >
            Add Secondary Education
          </button>
        </>
      )}
    </>
  );
};

export default CandidateSE;
